import { createApp } from 'vue'
// import App from './views/Home/Index.vue'
import router from './router'
import store from './store'

import elementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import 'element-plus/dist/index.css'
import '@/assets/css/common.css'
import '@/assets/css/commonl.less'

import Vant from "vant"
import "vant/lib/index.css"

const setRem = () => {
    // 假设设计稿宽度为750px
    const baseSize = 750;
    // 获取当前设备的宽度
    const clientWidth = document.documentElement.clientWidth || document.body.clientWidth;
    // 设置html的字体大小
    document.documentElement.style.fontSize = (clientWidth / baseSize) * 100 + 'px';
};
   
  // 初始化设置
  // setRem();
  // 窗口大小改变时重新设置
  // window.onresize = setRem;

import App from './App.vue'

createApp(App).use(store).use(router).use(Vant).use(elementPlus, {
    locale: zhCn,
}).mount('#app')
