<template>
  <div>
    <router-view/>
  </div>
</template>
<script lang="ts">
export default {
  
}
</script>
<style lang="less">
@media screen and (min-width: 768px) {
    #bgImg {
        background-image: url("@/assets/images/lg.png");
        background-size: 100%;
    }
}

@media screen and (max-width: 768px) {
    #bgImg {
        background-image: url("@/assets/images/sm.png");
        background-size: 100%;
    }
}

#bgImg {
    background-repeat: repeat-y;
    background-position: center top;
    position: fixed;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-color: #070023;
    top: 0;
    left: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
}
.line{
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.line-columns(@n, @i:1) when(@i =< @n){
  .line-@{i}{
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: @i;
    line-clamp: @i;
    -webkit-box-orient: vertical;
}
.line-columns(@n, (@i + 1));
}

.line-columns(7);
.el-picker-panel{
    background: rgba(28, 8, 46, 0.6) !important;
}
.el-date-picker table,.el-date-picker__header-label, .el-date-table th, .el-icon, .el-input__inner, .el-month-table td .cell, .el-year-table td .cell{
    color: #fff;
}
.el-popper.is-light .el-popper__arrow::before{
  background: unset
}
.el-message{
    margin-top: 20% !important; z-index: 9999999999999 !important;
}
.w-e-bar, .w-e-text-container, .w-e-bar-divider, .w-e-bar-item button:hover, .w-e-bar-item button,.w-e-text-container [data-slate-editor] blockquote,
.w-e-select-list, .w-e-select-list ul .selected, .w-e-select-list ul li:hover, .w-e-drop-panel, .w-e-bar-item-group .w-e-bar-item-menus-container,
.w-e-text-container [data-slate-editor] table th{
 background-color: rgba(28, 8, 46, 0.6) !important; color: #fff !important;
}
.w-e-bar svg{
  fill: #fff !important;
}
.vcp-poster-pic.cover, .vcp-poster-pic.default{
  width: 100%; height: 100%;
}
.el-date-table td.disabled .el-date-table-cell{
    background-color: rgba(168,171,178, 0.2) !important;
}
</style>
