import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  // 首页
  {
    path: '/index',
    name: 'home',
    
    component: () => import('@/views/Home/Index.vue')
  },
  // 党政专区
  {
    path: '/partyBuildingActivities',
    name: 'partyBuildingActivities',
    component: () => import('@/views/Home/PartyBuildingActivities.vue')
  },
  // 关于我们
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: () => import('@/views/Home/AboutUs.vue')
  },
  // 行业要闻
  {
    path: '/industryNews',
    name: 'industryNews',
    component: () => import('@/views/Home/IndustryNews.vue')
  },
  // 规章制度
  {
    path: '/rulesAndRegulations',
    name: 'rulesAndRegulations',
    component: () => import('@/views/Home/RulesAndRegulations.vue')
  },
  // 赛事信息
  {
    path: '/compete',
    name: 'compete',
    component: () => import('@/views/Home/Compete.vue')
  },
  // 中国之队
  {
    path: '/chinaTeam',
    name: 'chinaTeam',
    component: () => import('@/views/Home/ChinaTeam.vue')
  },
  // 国家资讯
  {
    path: '/nationalInformation',
    name: 'nationalInformation',
    component: () => import('@/views/Home/NationalInformation.vue')
  },
  // 运动技术等级考试
  {
    path: '/examInformation',
    name: 'examInformation',
    component: () => import('@/views/Home/ExamInformation.vue')
  },
  // 运动技术等级考试视频更多
  {
    path: '/examInformationVideo',
    name: 'examInformationVideo',
    component: () => import('@/views/Home/ExamInformationVideo.vue')
  },
  // 证书查询
  {
    "path": "/certificateQuery",
    "name": "certificateQuery",
    "component": () => import('@/views/Home/CertificateQuery.vue')
  },
  // 证书查询结果
  {
    "path": "/certificateQueryResult",
    "name": "certificateQueryResult",
    "component": () => import('@/views/Home/CertificateQueryResult.vue')
  },
  // 资讯详情
  {
    path: '/articleDetail',
    name: 'articleDetail',
    component: () => import('@/views/Home/ArticleDetail.vue')
  },
  // 注册 —— 选身份
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/Home/Register.vue')
  },
  // 注册 —— 个人
  {
    path: '/register/individual',
    name: 'registerIndividual',
    component: () => import('@/views/Home/RegisterIndividual.vue')
  },
  // 注册 —— 机构
  {
    path: '/register/company',
    name: 'registerCompany',
    component: () => import('@/views/Home/RegisterCompany.vue')
  },
  // 注册 —— 成功
  {
    path: '/register/success',
    name: 'registerSuccess',
    component: () => import('@/views/Home/RegisterSuccess.vue')
  },
  // 赛事详情
  {
    path: '/competeDetail',
    name: 'competeDetail',
    component: () => import('@/views/Home/CompeteDetail.vue')
  },
  // 赛事详情 —— 相关资讯 、精彩瞬间 、视频回放
  {
    path: '/compete/more',
    name: 'competeMore',
    component: () => import('@/views/Home/CompeteMore.vue')
  },
  // 搜索 —— 资讯、赛事、视频、相册
  {
    path: '/search',
    name: 'search',
    component: () => import('@/views/Home/Search.vue')
  },
  // 个人中心
  {
    path: '/userCenter',
    name: 'userCenter',
    component: () => import('@/views/Home/UserCenter.vue')
  },
  // 视频详情
  {
    path: '/videoDetail',
    name: 'videoDetail',
    component: () => import('@/views/Home/VideoDetail.vue')
  },
  // 下载地址
  {
    path: '/resourceDownload',
    name: 'resourceDownload',
    component: () => import('@/views/Home/ResourceDownload.vue')
  },
  // 裁判公示
  {
    path: '/RefereePublicity',
    name: 'RefereePublicity',
    component: () => import('@/views/Home/RefereePublicity.vue')
  },
  // 教练员（教师）公示
  {
    path: '/teacherPublicity',
    name: 'teacherPublicity',
    component: () => import('@/views/Home/TeacherPublicity.vue')
  },
  // 裁判详情
  {
    path: '/RefereeDetail',
    name: 'RefereeDetail',
    component: () => import('@/views/Home/RefereeDetail.vue')
  },
  // 教师详情
  {
    path: '/teacherDetail',
    name: 'teacherDetail',
    component: () => import('@/views/Home/TeacherDetail.vue')
  },
  // 注册裁判
  {
    path: '/RefereeRegister',
    name: 'RefereeRegister',
    component: () => import('@/views/Home/RefereeRegister.vue')
  },
  // 注册裁判审核页
  {
    path: '/RefereeRegisterS',
    name: 'RefereeRegisterS',
    component: () => import('@/views/Home/RefereeRegisterS.vue')
  },
  // 注册教师
  {
    path: '/teacherRegister',
    name: 'teacherRegister',
    component: () => import('@/views/Home/TeacherRegister.vue')
  },
  // 注册教师审核页
  {
    path: '/TeacherRegisterS',
    name: 'TeacherRegisterS',
    component: () => import('@/views/Home/TeacherRegisterS.vue')
  },
  // 地方协会
  {
    path: '/association',
    name: 'association',
    component: () => import('@/views/Home/Association.vue')
  },
  // 视频合集
  {
      path: '/videoCollection',
      name: 'videoCollection',
      component: () => import('@/views/Home/VideoCollection.vue')
  },
  // 视频合集——合集详情
  {
      path: '/videoCollectionDetail',
      name: 'videoCollectionDetail',
      component: () => import('@/views/Home/VideoCollectionDetail.vue')
  },
  // 相册详情
  {
    path: '/photoAlbum',
    name: 'photoAlbum',
    component: () => import('@/views/Home/PhotoAlbum.vue')
  },
  // 直播详情
  {
    path: '/liveDetail',
    name: 'liveDetail',
    component: () => import('@/views/Home/LiveDetail.vue')
  },
  // app 下载
  {
    path: '/appDownload',
    name: 'appDownload',
    component: () => import('@/views/Home/AppDownload.vue')
  },
  // 忘记密码
  {
    path: '/forget',
    name: 'forget',
    component: () => import('@/views/Home/Forget.vue')
  },
  // 找回密码
  {
    path: '/forgetPassword',
    name: 'forgetPassword',
    component: () => import('@/views/Home/ForgetPassword.vue')
  },
  // 绑定手机号
  {
    path: '/bindMobile',
    name: 'bindMobile',
    component: () => import('@/views/Home/BindMobile.vue')
  },
  // 路由重定向
  {
    path: "/",
    redirect: "/index"
  },


  // h5
  // 视频专辑
  {
    path: '/h5/videoAlbum',
    name: 'H5VideoAlbum',
    component: () => import('@/views/H5/VideoAlbum.vue')
  },
  // 视频专辑详情
  {
    path: '/h5/videoAlbumDetail',
    name: 'H5VideoAlbumDetail',
    component: () => import('@/views/H5/VideoAlbumDetail.vue')
  },
  // 视频详情页
  {
    path: '/h5/videoDetail',
    name: 'H5VideoDetail',
    component: () => import('@/views/H5/VideoDetail.vue')
  },
  // 赛事视频列表页
  {
    path: '/h5/matchVideoList',
    name: 'H5MatchVideoList',
    component: () => import('@/views/H5/MatchVideoList.vue')
  },
  // 首页
  {
    path: '/h5/index',
    name: 'H5Index',
    component: () => import('@/views/H5/Index.vue')
  },
  // 赛事列表
  {
    path: '/h5/competeList',
    name: 'H5CompeteList',
    component: () => import('@/views/H5/CompeteList.vue')
  },
  // 赛事详情
  {
    path: '/h5/competeDetail',
    name: 'H5CompeteDetail',
    component: () => import('@/views/H5/CompeteDetail.vue')
  },
  // 赛事介绍
  {
    path: '/h5/competeDetail/introduce',
    name: 'H5CompeteIntroduce',
    component: () => import('@/views/H5/CompeteIntroduce.vue')
  },
  // 裁判委员会
  {
    path: '/h5/competeDetail/referee',
    name: 'H5CompeteReferee',
    component: () => import('@/views/H5/CompeteReferee.vue')
  },
  // 相关资讯
  {
    path: '/h5/competeDetail/article',
    name: 'H5CompeteArticle',
    component: () => import('@/views/H5/CompeteArticle.vue')
  },
  // 相关视频
  {
    path: '/h5/competeDetail/video',
    name: 'H5CompeteVideo',
    component: () => import('@/views/H5/CompeteVideo.vue')
  },
  // 相关相册
  {
    path: '/h5/competeDetail/album',
    name: 'H5CompeteAlbum',
    component: () => import('@/views/H5/CompeteAlbum.vue')
  },
  // 资讯详情
  {
    path: '/h5/articleDetail',
    name: 'H5ArticleDetail',
    component: () => import('@/views/H5/ArticleDetail.vue')
  },
  // 相册详情
  {
    path: '/h5/albumDetail',
    name: 'H5AlbumDetail',
    component: () => import('@/views/H5/AlbumDetail.vue')
  },
  // 图片详情
  {
    path: '/h5/photoDetail',
    name: 'H5PhotoDetail',
    component: () => import('@/views/H5/PhotoDetail.vue')
  },
  // 秩序册详情
  {
    path: '/h5/program',
    name: 'H5Program',
    component: () => import('@/views/H5/Program.vue')
  },
  // 实时成绩
  {
    path: '/h5/score',
    name: 'H5Score',
    component: () => import('@/views/H5/Score.vue')
  },
  // 实时成绩单元列表
  {
    path: '/h5/unitList',
    name: 'H5SUnitList',
    component: () => import('@/views/H5/UnitList.vue')
  },
  // mark 成绩详情
  {
    path: '/h5/markDetail',
    name: 'H5MarkDetail',
    component: () => import('@/views/H5/MarkDetail.vue')
  },
  // 决赛详情
  {
    path: '/h5/finalDetail',
    name: 'H5FinalDetail',
    component: () => import('@/views/H5/FinalDetail.vue')
  },
  // 决赛顺位表
  {
    path: '/h5/rankingDetail',
    name: 'H5RankingDetail',
    component: () => import('@/views/H5/RankingDetail.vue')
  },
  // 证书查询
  {
    path: '/h5/certificate',
    name: 'H5Certificate',
    component: () => import('@/views/H5/Certificate.vue')
  },
  // 证书查询详情页
  {
    path: '/kj/kj',
    name: 'H5CertificateQuery',
    component: () => import('@/views/H5/CertificateQuery.vue')
  },
  // app 下载页
  {
    path: '/h5/download',
    name: 'App',
    component: () => import('@/views/H5/Download.vue')
  },
  // 直播列表页
  {
    path: '/h5/liveList',
    name: 'H5LiveList',
    component: () => import('@/views/H5/LiveList.vue')
  },
  // 直播详情
  {
    path: '/h5/liveDetail',
    name: 'H5LiveDetail',
    component: () => import('@/views/H5/LiveDetail.vue')
  },







  {
    path: '/hello',
    name: 'hello',
    component: () => import('@/views/HomeView.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router